import React from 'react';
import { SeatPosition } from '@web/shop-logic/dist/hooks/types';
import { SeatSection } from '@web/shop-logic/dist/hooks/useSeats';
import SeatingCardInner from '@/components/seating/SeatingCardInner';
import { useSeatSelection } from '@/hooks/useSeatSelection';

interface StornoSeatingCardProps {
  routeSection: SeatSection;
  onSeatSelect: (newSeats: SeatPosition[]) => void;
  handleNext: () => void;
}

const StornoSeatingCard: React.FC<StornoSeatingCardProps> = ({
  routeSection,
  onSeatSelect,
  handleNext,
}) => {
  const { vehicleNr, selectedDeckNr, setSelectedDeckNr, setVehicleNr } =
    useSeatSelection(routeSection);

  return (
    <div key={routeSection.sectionId}>
      <SeatingCardInner
        selectedDeckNr={selectedDeckNr}
        setSelectedDeckNr={setSelectedDeckNr}
        onContinue={handleNext}
        onSeatSelect={onSeatSelect}
        seatSection={routeSection}
        selectedSeats={routeSection.selectedSeats}
        setVehicleNr={setVehicleNr}
        vehicleNr={vehicleNr}
        openedFromTicket
        isStorno
      />
    </div>
  );
};

export default StornoSeatingCard;
