import React from 'react';
import classNames from 'classnames';

interface WagonWheelProps {
  customStyle: string;
}

const WagonWheel: React.FC<WagonWheelProps> = ({ customStyle }) => {
  return (
    <div
      className={classNames(
        customStyle,
        'absolute right-[-7px] w-0.75 h-[10px] border border-l-0 rounded-r-full border-neutral-gray2',
      )}
    />
  );
};

export default WagonWheel;
