import React from 'react';
import { SeatPosition } from '@web/shop-logic/dist/hooks/types';
import SeatWagonBadge from '@/components/seating/SeatWagonBadge';
import { groupBy } from '@/utils/arrayUtils';

interface SeatWagonBadgesProps {
  className?: string;
  removeWagon?: boolean;
  selectedSeats: SeatPosition[];
}

const SeatWagonBadges: React.FC<SeatWagonBadgesProps> = ({
  className,
  removeWagon,
  selectedSeats,
}) => {
  const seatGroups = groupBy(selectedSeats, (s) => s.vehicleNumber);

  return (
    <div className={className}>
      {Array.from(seatGroups.entries()).map(([k, v]) => (
        <SeatWagonBadge key={k} seats={v} removeWagon={removeWagon} />
      ))}
    </div>
  );
};

export default SeatWagonBadges;
