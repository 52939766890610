import Alert from '../atoms/Alert';
import { Button, ButtonLink } from '../atoms/Button';
import ModalContext from '../modal/ModalContext';
import { Trans } from 'next-i18next';
import { useRouter } from 'next/router';
import { useContextSelector } from 'use-context-selector';
import React, { FC } from 'react';
import URLS from '@/constants/url';
import useCurrentBooking from '@/hooks/useCurrentBooking';
import { ArrowWest } from '@/icons';

interface OtherClassModalProps {
  name: string;
}

const OtherClassModal: FC<OtherClassModalProps> = ({ name }) => {
  const { query } = useRouter();
  const { direction } = useCurrentBooking();
  const hideAllModals = useContextSelector(ModalContext, (c) => c.hideAllModals);

  const backLink = {
    pathname: `${URLS.RESERVATION.FARE}/${direction}`,
    query,
  };

  return (
    <div className="flex flex-col gap-3">
      <Alert variant="info">
        <Trans i18nKey="warning.otherClass.description" />
      </Alert>
      <div className="flex gap-2 self-end sm:flex-col w-full">
        <ButtonLink href={backLink} size="big" variant="secondary">
          <ArrowWest className="w-3 h-3 fill-current mr-1" />
          <Trans i18nKey="back.toFare" />
        </ButtonLink>
        <Button onClick={hideAllModals} size="big" variant="primary">
          <Trans i18nKey="fare.upsell.modal.upgrade.stay" /> &nbsp;
          <span className="first-letter:capitalize lowercase">{name}</span>
        </Button>
      </div>
    </div>
  );
};

export default OtherClassModal;
