import { captureMessage } from '@sentry/nextjs';
import { SeatPosition } from '@web/shop-logic/dist/hooks/types';
import { PreselectedSeat } from '@web/shop-logic/dist/hooks/useSeats';

/**
 * Vrací elementy uvnitř elementu sedadla
 *
 * Starý a nový formát SVG se liší písmenem v ID a strukturou:
 * Starý formát SVG = circle + text
 * Nový formát SVG = circle + background + text
 * Tato funkce funguje na obou formátech (předpokládá se nový formát)
 *
 * Poznámka k selectoru:
 * ID elementů obsahuje unikátní hash, který se nemění při změně source URL
 * (narozdíl od atributu ID v SVG), proto je selektor ve tvaru:
 *  - aktuální ID SVG
 *  - ID elementu začínající na `seatIndex` (unikátní hash se ignoruje)
 */
export const getSeatElements = (
  coachRef: SVGElement,
  seatIndex: number,
): Record<string, Element> => {
  // Element obsahující circle + background + text, match končí podtržítkem protože má vygenerované coach ID
  const seatEl = coachRef.querySelector(`g[id='s${seatIndex}']`);

  /**
   * Pokud element neexistuje (má špatné ID v SVG souboru), ukonči funkci (sedadlo nebude klikací)
   * Do Sentry logujeme všechny neexistující sedadla
   */
  if (!seatEl) {
    captureMessage(`Element nenalezen: [id='s${seatIndex}']`);
    return {};
  }

  // Starý formát SVG jako fallback
  const circle = seatEl.querySelector(`[id='c${seatIndex}'], [id='b${seatIndex}']`);
  const background = seatEl.querySelector(`[id='a${seatIndex}']`);
  const text = seatEl.querySelector(`[id='n${seatIndex}']`);

  return { circle, background, text };
};

/**
 * Vrací TRUE, pokud atribut ID odpovídá elementu sedadla nebo jakékoliv části v něm
 */
export const isSeatElementId = (id: string): boolean => /^(s|c|b|a|n)([0-9]{1,3}$)/g.test(id);

/**
 * Vrací TRUE, pokud sedadlo existuje v `seatsArray` a zároveň odpovídá i vehicleNumber
 * @var seatIndex `seatIndex` hledaného sedadla
 * @var vehicleNumber `vehicleNumber` hledaného sedadla
 * @var seatsArray pole sedadel, ve kterém se hledá
 */
export const isSeatIncluded = (
  seatIndex: number,
  vehicleNumber: number,
  // vehicleDeckNumber: number,
  seatsArray: (SeatPosition & Partial<PreselectedSeat>)[],
): boolean =>
  seatsArray.some((seat) => seat.seatIndex === seatIndex && seat.vehicleNumber === vehicleNumber);

/**
 * Vezme URL z backendu a sestaví lokální path do složky `/public/vehicles`
 */
export const getStaticPathFromUrl = (url: string): string => {
  const fileName = url?.split('/')?.pop();
  return fileName ? `/vehicles/${fileName}` : null;
};
