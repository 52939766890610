import { Trans } from 'next-i18next';
import React from 'react';
import classNames from 'classnames';
import { SeatPosition } from '@web/shop-logic/dist/hooks/types';
import { Seat, TrainWagon } from '@/icons';

const iconClasses = 'w-2.5 h-2.5 mr-0.5';

export interface SeatWagonBadgeProps {
  className?: string;
  removeWagon?: boolean;
  seats: SeatPosition[];
}

/**
 * Číslo vozu a sedadla
 */
const SeatWagonBadge: React.FC<SeatWagonBadgeProps> = ({ className, removeWagon, seats }) => (
  <div
    className={classNames(
      'inline-flex px-2 py-1 rounded-sm bg-primary-yellow font-bold',
      className,
    )}
  >
    {/* Vybraná sedadla: */}
    <span className="sr-only">
      <Trans i18nKey="reservation.selectedSeats" />:
    </span>
    {/* Číslo vozu */}
    {!removeWagon && (
      <span className="flex">
        <TrainWagon className={iconClasses} />
        <span className="sr-only">
          <Trans i18nKey="accesibility.vehicle" />
        </span>
        {[...new Set(seats.map((s) => s.vehicleNumber))].join(', ')}
        {/* Oddělovač */}
        <div className="border-l border-neutral-black border-opacity-30 mx-1.5" />
      </span>
    )}
    {/* Číslo sedadla */}
    <Seat className={iconClasses} />
    <span className="sr-only">
      <Trans i18nKey="accesibility.seat" />
    </span>
    {seats.map((seat) => seat.seatIndex).join(', ')}
  </div>
);

export default SeatWagonBadge;
