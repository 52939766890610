import useModal from '../modal/useModal';
import OtherClassModal from './OtherClassModal';
import { TSelectedDeck } from './SeatingCard';
import { Trans, useTranslation } from 'next-i18next';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import InlineSVG from 'react-inlinesvg';
import { makeStyles } from 'tss-react/mui';
import classNames from 'classnames';
import { captureMessage } from '@sentry/nextjs';
import { SeatClass, SeatPosition } from '@web/shop-logic/dist/hooks/types';
import { Deck } from '@web/shop-logic/dist/hooks/useSeats';
import { Button } from '@/components/atoms/Button';
import { StornoContext } from '@/components/seating/PartialStornoSeatingCards';
import useContext from '@/hooks/useContext';
import { fillSeats } from '@/utils/seatingFillUtils';
import { isSeatIncluded, getStaticPathFromUrl, isSeatElementId } from '@/utils/seatingUtils';

const defaultDoubleDeckerClasses = 'sm:sticky transform transition-all duration-500 ease-in-out';

export const getClassSeatSelectCount = (cls: SeatClass) => {
  if (cls === 'TRAIN_COUCHETTE_BUSINESS_4') return 4;
  if (cls === 'TRAIN_COUCHETTE_BUSINESS') return 3;
  return 1;
};

const useStyles = makeStyles()(() => ({
  root: {
    marginLeft: 'auto',
    '& [id^=n]': { fontFamily: 'Inter, sans-serif', fontSize: '0.75rem' },
  },
}));

const getSvgClasses = (
  doubleDecker: boolean,
  selectedDeckNr: TSelectedDeck,
  deck: Deck,
): string | undefined => {
  const isSelected = selectedDeckNr === deck.number;

  if (!doubleDecker) return '';
  else if (selectedDeckNr === 1 && isSelected)
    return `${defaultDoubleDeckerClasses} scale-105 sm:translate-x-5 sm:z-10`;
  else if (selectedDeckNr === 1 && !isSelected)
    return `${defaultDoubleDeckerClasses} scale-90 sm:-translate-x-5 opacity-60 sm:-z-5`;
  else if (selectedDeckNr === 2 && isSelected)
    return `${defaultDoubleDeckerClasses} scale-105 sm:-translate-x-5 sm:z-10`;
  else if (selectedDeckNr === 2 && !isSelected)
    return `${defaultDoubleDeckerClasses} scale-90 sm:translate-x-5 opacity-60 sm:-z-5`;
};

interface CoachSvgProps {
  onSeatSelect: (seats: SeatPosition[]) => void;
  seatSectionId: number;
  selectedSeats: SeatPosition[];
  deck: Deck;
  vehicleNumber: number;
  selectedDeckNr?: TSelectedDeck;
  doubleDecker?: boolean;
  switchDecks?: () => void;
  firstSelectedDeck?: TSelectedDeck;
  openedFromTicket?: boolean;
  otherDeck?: Deck;
}

const CoachSvg: React.FC<CoachSvgProps> = ({
  onSeatSelect,
  seatSectionId,
  selectedSeats = [],
  deck,
  vehicleNumber,
  selectedDeckNr,
  doubleDecker,
  switchDecks,
  firstSelectedDeck,
  openedFromTicket,
  otherDeck,
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { showModal } = useModal();

  const [svgLoaded, setSvgLoaded] = useState(false);
  const [svgError, setSvgError] = useState(false);
  const {
    seatsForStorno,
    setSeatsForStorno,
    index: routeIndex,
    isPartialStorno,
  } = useContext(StornoContext);
  const ref = useRef<SVGElement>(null);

  /**
   * Zobrazí tlačítka vagonů (dostupné pouze pro screenreader)
   */
  const [showScreenreaderOptions, setShowScreenreaderOptions] = useState(false);
  /**
   * Aktuální seznam volných míst ve voze
   * @screenreaderOptions Pole komponent vypisující tlačítka
   *    = prázdné pole - žádná volná místa
   *    = null - resetováno při změně vozu, signál pro načtení nového pole
   */
  const [screenreaderOptions, setScreenreaderOptions] = useState<React.FC[] | null>(null);

  const openModal = () =>
    showModal(<OtherClassModal name={otherDeck?.name} />, { title: t('warning.otherClass.title') });

  const isDeckSelected = selectedDeckNr === deck.number;
  const isOtherClassSeats = deck.number !== firstSelectedDeck && doubleDecker;

  const changeStornoSeat = (seatIndex: number) => {
    /**
     * selectedSeats - koupené jízdenky, ze kterých vybíráme, které stornujem
     * seatsForStorno - všechna místa ve všech spojích ve všech vagonech, které jsme vybrali ke stornu ze `selectedSeats`
     */

    if (
      // Ignoruj kliknutí na obsazená sedadla (při stornu je selectedSeats pole dostupných sedadel)
      !isSeatIncluded(seatIndex, vehicleNumber, selectedSeats) ||
      // Ignoruj kliknutí na uživatelem již vybraná sedadla
      isSeatIncluded(seatIndex, vehicleNumber, seatsForStorno?.[routeIndex])
    ) {
      return;
    }

    // Nově vybrané sedadlo se dá na začátek pole (při opakované změně odstraňuje poslední vybrané)
    const newSeatsForStorno = [...seatsForStorno];
    newSeatsForStorno[routeIndex] = [
      { seatIndex, vehicleNumber, vehicleDeckNumber: deck.number, sectionId: seatSectionId },
      ...newSeatsForStorno[routeIndex].slice(0, seatsForStorno?.[routeIndex].length - 1),
    ];

    // Pošle nově vybraná sedadla do kontextu
    setSeatsForStorno(newSeatsForStorno);
  };

  const changeSeat = (seatIndex: number) => {
    // Ignoruj kliknutí na obsazená sedadla (při kupování je selectedSeats pole uživatelem vybraných sedadel)
    if (isSeatIncluded(seatIndex, vehicleNumber, selectedSeats)) {
      return;
    }
    const seat = deck.freeSeats.find((s) => s.index === seatIndex);

    if (seat) {
      const newSelectedSeats = [...selectedSeats, { seatIndex, vehicleNumber }].slice(
        -selectedSeats.length,
      );

      onSeatSelect(newSelectedSeats);

      fillSeats(
        ref.current,
        vehicleNumber,
        deck.number,
        isPartialStorno,
        seatsForStorno?.[routeIndex],
        deck.occupiedSeats,
        deck.freeSeats,
        newSelectedSeats,
        isOtherClassSeats,
      );
    }
  };

  const handleSeatClick: React.MouseEventHandler<SVGElement> = ({ target }) => {
    if (!isDeckSelected && doubleDecker) {
      switchDecks?.();
      return;
    }

    if (target instanceof Element) {
      if (!isSeatElementId(target.id)) return;

      const seatIndex = +target.id.replace(/\D/g, '');

      if (
        isOtherClassSeats &&
        !openedFromTicket &&
        deck.freeSeats.some((seat) => seat.index === seatIndex)
      ) {
        openModal();
        return;
      }

      const changeFnc = isPartialStorno ? changeStornoSeat : changeSeat;
      if (seatIndex) changeFnc(seatIndex);
    }
  };

  useEffect(() => {
    setSvgLoaded(false);
  }, [deck.number, vehicleNumber]);

  useLayoutEffect(() => {
    if (selectedSeats.length && svgLoaded && ref.current) {
      fillSeats(
        ref.current,
        vehicleNumber,
        deck.number,
        isPartialStorno,
        seatsForStorno?.[routeIndex],
        deck.occupiedSeats,
        deck.freeSeats,
        selectedSeats,
        isOtherClassSeats,
      );
      setScreenreaderOptions(null);
    }
  }, [svgLoaded, selectedSeats, seatsForStorno, isOtherClassSeats, deck, ref.current]);

  /**
   * a11y verze
   * Namísto sedadel v SVG se zobrazí seznam buttonů, které kliknutím vyberou sedadlo
   */

  useEffect(() => {
    if (showScreenreaderOptions && screenreaderOptions === null) {
      let elements;

      if (isPartialStorno) {
        /**
         * Modal Částečné storno
         */
        const seatsForStornoIndexes = (seatsForStorno?.[routeIndex] || []).map(
          (item) => item.seatIndex,
        );
        // eslint-disable-next-line react/display-name
        elements = (selectedSeats || []).map(({ seatIndex }) => () => {
          const isForStorno = seatsForStornoIndexes.includes(seatIndex)
            ? t('ticket.partial.storno.seating.map.storno.sign')
            : '';
          return (
            <Button
              aria-label={`${t('accessibility.icon.seat', { number: seatIndex })} ${isForStorno}`}
              onClick={() => changeStornoSeat(seatIndex)}
              gtmPlace="SeatingCard"
              gtmName="Choose seat - a11y"
            />
          );
        });
      } else {
        /**
         * Výběr sedadla (nákup jízdenky)
         */
        // eslint-disable-next-line react/display-name
        elements = deck.freeSeats.map(({ index }) => () => (
          <Button
            aria-label={t('accessibility.icon.seat', { number: index })}
            onClick={() => changeSeat(index)}
            gtmPlace="SeatingCard"
            gtmName="Choose seat - a11y"
          />
        ));
      }
      setScreenreaderOptions(elements);
    }
  }, [showScreenreaderOptions, screenreaderOptions]);

  return (
    <div className={classes.root}>
      <InlineSVG
        key={`${deck.number}-${vehicleNumber}-${seatSectionId}`}
        innerRef={ref}
        className={classNames(
          'max-w-full select-none',
          getSvgClasses(doubleDecker, selectedDeckNr, deck),
        )}
        // Načítáme SVG soubor ze složky `/public`
        src={svgError ? deck.layoutURL : getStaticPathFromUrl(deck.layoutURL)}
        onLoad={() => setSvgLoaded(true)}
        // Fallback: Pokud SVG nemáme ve složce `/public`, stáhne se z backendu
        onError={() => {
          captureMessage(`SVG soubor nenalezen: ${getStaticPathFromUrl(deck.layoutURL)}`);
          setSvgError(true);
        }}
        onClick={handleSeatClick}
        aria-hidden
      />

      {/**
       * a11y tlačítko: zobrazí tlačítkové sedadla namísto SVG
       */}
      <Button
        className="sr-only"
        aria-label={t('accesibility.seating.showSeats')}
        onClick={() => setShowScreenreaderOptions(true)}
        gtmPlace="SeatingCard"
        gtmName="Show seating - a11y"
      />
      {/**
       * a11y tlačítková sedadla
       */}
      {showScreenreaderOptions && (
        <nav
          aria-label={t(
            isPartialStorno
              ? 'ticket.passengerCancelModal.storno.title'
              : 'accessibility.ticket.vehicleLayout',
          )}
          className="sr-only"
        >
          {screenreaderOptions?.length === 0 ? (
            <Trans i18nKey="accesibility.seating.noFreeSeats" />
          ) : (
            (screenreaderOptions || []).map((Option, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Option key={index} />
            ))
          )}
        </nav>
      )}
    </div>
  );
};

export default CoachSvg;
