import { useEffect, useState } from 'react';
import { SeatSection } from '@web/shop-logic/dist/hooks/useSeats';
import { TSelectedDeck } from '@/components/seating/SeatingCard';

export const useSeatSelection = (seatSection: SeatSection) => {
  const [vehicleNr, setVehicleNr] = useState(seatSection?.selectedSeats?.[0]?.vehicleNumber);
  const [selectedDeckNr, setSelectedDeckNr] = useState<TSelectedDeck>(
    seatSection?.selectedSeats?.[0]?.vehicleDeckNumber,
  );

  useEffect(() => {
    if (!seatSection || !seatSection.fixedSeatReservation) return;
    const ss = seatSection.selectedSeats.map((s) => ({
      ...s,
      vehicleDeckNumber:
        seatSection.vehicles
          .find((v) => v.number === s.vehicleNumber)
          ?.decks.find((d) => d.freeSeats.some((seat) => seat.index === s.seatIndex))?.number ??
        s.vehicleDeckNumber,
    }));
    setVehicleNr(ss[0]?.vehicleNumber);
    setSelectedDeckNr(ss[0]?.vehicleDeckNumber as TSelectedDeck);
  }, [!!seatSection, seatSection?.sectionId]);

  return { vehicleNr, selectedDeckNr, setSelectedDeckNr, setVehicleNr };
};
