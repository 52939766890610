import { Trans, useTranslation } from 'next-i18next';
import React from 'react';
import { PriceClass } from '@web/shop-logic/dist/hooks/useConnectionRoute';
import SeatClassMoreInfo from '@/components/fare/SeatClassMoreInfo';

interface SeatFacilitiesProps {
  classInfo?: Partial<Pick<PriceClass, 'services' | 'type'>>;
  conditionsInfo?: Partial<Pick<PriceClass, 'conditions'>>;
  openedFromTicket: boolean;
  seatClassPhotoUrl?: string;
  standardTransKey: string;
}

const SeatFacilities: React.FC<SeatFacilitiesProps> = ({
  classInfo,
  seatClassPhotoUrl,
  standardTransKey,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {standardTransKey && (
        <h4 className="h4 mb-2">
          <Trans i18nKey={standardTransKey} />
        </h4>
      )}
      {!!seatClassPhotoUrl && (
        <img alt={t(standardTransKey)} className="rounded-sm mb-2 w-full" src={seatClassPhotoUrl} />
      )}
      {!!classInfo.services?.length && (
        <h4 className="h4 mb-2">
          <Trans i18nKey="coach.facilities" />
        </h4>
      )}
      <SeatClassMoreInfo services={classInfo?.services} />
    </>
  );
};

export default SeatFacilities;
