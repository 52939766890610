import { Trans } from 'next-i18next';
import React from 'react';
import { Seat } from '@web/shop-logic/dist/hooks/useSeats';

interface SeatConstraintsProps {
  seatConstraint: [string, Seat[]][];
}

const SeatConstraints: React.FC<SeatConstraintsProps> = ({ seatConstraint }) => (
  <>
    {seatConstraint.map(([constraintText, seats], index) => (
      <div key={index} className="mb-2">
        <span className="font-bold inline">
          <Trans i18nKey="ticket.labels.seats" />
          {seats.map(({ index: seatIndex }, i) => (
            <span key={seatIndex}>{` ${seatIndex}${i === seats.length - 1 ? ' -' : ','} `}</span>
          ))}
        </span>
        {constraintText}
      </div>
    ))}
  </>
);

export default SeatConstraints;
