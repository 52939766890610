import { Trans } from 'next-i18next';
import React from 'react';
import { Service } from '@web/shop-logic/dist/hooks/useConnectionRoute';
import {
  Accessible,
  Breakfast,
  Champagne,
  ChevronRight,
  ElectricalServices,
  FreeWater,
  GestureTapButton,
  Headphones,
  IconElement,
  IconElementProps,
  Juice,
  Kids,
  Language,
  LocalCafe,
  Lowcost,
  Meditation,
  MenuBook,
  OnlineCatering,
  NoElectricalServices,
  NoGestureTapButton,
  PedalBike,
  PriorityService,
  Restaurant,
  SeatFlat,
  Security,
  Silence,
  Snow,
  SofaSingle,
  Stevardess,
  Stroller,
  TableChair,
  VendingMachine,
  Wifi,
  WifiOff,
} from '@/icons';

const mapIconToService: Record<Service, IconElement> = {
  typy_vozidel_astra: GestureTapButton,
  typy_vozidel_automaty: VendingMachine,
  typy_vozidel_bezp_sluzba: Security,
  typy_vozidel_bez_dotykove_obrazovky: NoGestureTapButton,
  typy_vozidel_chlazene_napoje: FreeWater,
  typy_vozidel_denni_tisk: MenuBook,
  typy_vozidel_detske_kupe: Kids,
  typy_vozidel_dotykova_obrazovka: GestureTapButton,
  typy_vozidel_dzus: Juice,
  typy_vozidel_imobilni: Accessible,
  typy_vozidel_imobilni_rampa: Accessible,
  typy_vozidel_klidova_zona: Meditation,
  typy_vozidel_klimatizace: Snow,
  typy_vozidel_kocarky: Stroller,
  typy_vozidel_kresilko: SofaSingle,
  typy_vozidel_low_cost: Lowcost,
  typy_vozidel_obcerstveni: Restaurant,
  typy_vozidel_online_catering: OnlineCatering,
  typy_vozidel_prednostni_servis: PriorityService,
  typy_vozidel_preprava_kola: PedalBike,
  typy_vozidel_restauracni_vuz: TableChair,
  typy_vozidel_sekt: Champagne,
  typy_vozidel_sluchatka: Headphones,
  typy_vozidel_snidane: Breakfast,
  typy_vozidel_spaci_vuz: SeatFlat,
  typy_vozidel_spaci_vuz_zeny: SeatFlat,
  typy_vozidel_stevardka: Stevardess,
  typy_vozidel_teple_napoje_zdarma: LocalCafe,
  typy_vozidel_tiche_kupe: Silence,
  typy_vozidel_wifi_ano: Wifi,
  typy_vozidel_wifi_ne: WifiOff,
  typy_vozidel_zabavni_portal_ano: Language,
  typy_vozidel_zastuvka_ne: NoElectricalServices,
  typy_vozidel_zasuvka: ElectricalServices,
};

interface ServiceIconProps extends IconElementProps {
  service: Service;
  hasAriaLabel?: boolean;
}

const ServiceIcon: React.FC<ServiceIconProps> = ({ service, hasAriaLabel, ...props }) => {
  const Icon = mapIconToService[service] || ChevronRight;

  return (
    <>
      <Icon {...props} />
      {hasAriaLabel && (
        /* Ikona s textovým labelem (pokud je použita samostatně bez textu) */
        <span className="sr-only">
          <Trans i18nKey={`icons.popover.${service}`} />
        </span>
      )}
    </>
  );
};

export default ServiceIcon;
