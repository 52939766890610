import { SeatPosition, SelectedSeat } from '@web/shop-logic/dist/hooks/types';
import { Seat } from '@web/shop-logic/dist/hooks/useSeats';
import { getSeatElements, isSeatIncluded } from '@/utils/seatingUtils';

/**
 * Výběr sedadla při koupi:
 *    Dostupná sedadla: FREE (zelená)
 *    Vybráno uživatelem: SELECTED (žlutá)
 *
 * Výběr sedadla při částečném stornu:
 *    Dostupná sedadla: STORNO_FREE (žlutá)
 *    Vybráno uživatelem: STORNO_SELECTED (červená)
 *
 * Nelze vybrat uživatelem:
 *    Neaktivní s číslem (zakoupená jízdenka, storno): NONSELECTABLE (šedá)
 *    Neaktivní s X (koupě jízdenky): OCCUPIED
 */
export enum SeatVariant {
  FREE = 'free',
  SELECTED = 'selected',
  STORNO_FREE = 'storno-free',
  STORNO_SELECTED = 'storno-selected',
  OCCUPIED = 'occupied',
  NONSELECTABLE = 'nonselectable',
  OTHER_CLASS = 'other_class',
}

export const seatVariantColors = {
  [SeatVariant.FREE]: 'secondary-seatfree',
  [SeatVariant.SELECTED]: 'secondary-seatselected',
  [SeatVariant.STORNO_SELECTED]: 'secondary-redwarn',
  [SeatVariant.OCCUPIED]: 'neutral-gray',
  [SeatVariant.OTHER_CLASS]: 'neutral-white',
};

const cirleClasses = {
  default: 'fill-current',
  interactive: 'cursor-pointer',
  [SeatVariant.FREE]: `text-${seatVariantColors[SeatVariant.FREE]}`,
  [SeatVariant.SELECTED]: `text-${seatVariantColors[SeatVariant.SELECTED]}`,
  [SeatVariant.STORNO_FREE]: `text-${seatVariantColors[SeatVariant.SELECTED]}`,
  [SeatVariant.STORNO_SELECTED]: `text-${seatVariantColors[SeatVariant.STORNO_SELECTED]}`,
  [SeatVariant.OCCUPIED]: `text-${seatVariantColors[SeatVariant.OCCUPIED]}`,
  [SeatVariant.NONSELECTABLE]: `text-${seatVariantColors[SeatVariant.OCCUPIED]}`,
  [SeatVariant.OTHER_CLASS]: `text-${seatVariantColors[SeatVariant.OTHER_CLASS]}`,
};

const backgroundClasses = {
  default: 'fill-current',
  interactive: 'cursor-pointer',
  [SeatVariant.FREE]: 'text-secondary-greendark',
  [SeatVariant.SELECTED]: 'text-primary-yellow',
  [SeatVariant.STORNO_FREE]: 'text-primary-yellow',
  [SeatVariant.STORNO_SELECTED]: 'text-secondary-reddark',
  [SeatVariant.OCCUPIED]: 'text-secondary-seatoccupied',
  [SeatVariant.NONSELECTABLE]: 'text-secondary-seatoccupied',
  [SeatVariant.OTHER_CLASS]: 'text-neutral-gray',
};

const textClasses = {
  default: 'fill-current',
  interactive: 'cursor-pointer',
  [SeatVariant.FREE]: 'text-neutral-white',
  [SeatVariant.SELECTED]: 'text-neutral-black',
  [SeatVariant.STORNO_FREE]: 'text-neutral-black',
  [SeatVariant.STORNO_SELECTED]: 'text-neutral-white',
  [SeatVariant.OCCUPIED]: 'text-neutral-white',
  [SeatVariant.NONSELECTABLE]: 'text-neutral-white',
  [SeatVariant.OTHER_CLASS]: 'text-neutral-black',
};

/**
 * Řeší jiné zobrazení při "kupování" a "stornem" jízdenky
 * Při stornu se zobrazují selectedSeats jako oranžové a seatsForStorno jako červené
 * V obou případech se používá proměnná `selectedSeats`
 * Při koupi jsou v ní uživatelsky vybraná sedadla, při stornu slouží jako pole dostupných sedadel
 */
const getSelectedSeatVariant = (
  seatIndex: number,
  isPartialStorno: boolean,
  vehicleNumber: number,
  deckNumber: number,
  userSelectedStornoSeats: SelectedSeat[],
): SeatVariant => {
  if (isPartialStorno) {
    return isSeatIncluded(seatIndex, vehicleNumber, userSelectedStornoSeats)
      ? SeatVariant.STORNO_SELECTED
      : SeatVariant.STORNO_FREE;
  }
  return SeatVariant.SELECTED;
};

/**
 * Obarví 1 sedadlo
 * @var variant určuje barvu sedadla
 */
const fillSingleSeat = (
  seatIndex: number,
  coachRef: SVGElement,
  variant: `${SeatVariant}`,
): void => {
  const { circle, background, text } = getSeatElements(coachRef, seatIndex);

  if (!circle || !text) {
    return;
  }
  /**
   * 1. Vymazání všech tříd na elementu
   * 2. Přidání tříd s barvami podle varianty
   * 3. Vyplnění čísla sedadla nebo křížku, pokud nelze vybrat
   */
  const isInteractive = variant !== SeatVariant.NONSELECTABLE && variant !== SeatVariant.OCCUPIED;

  circle.classList.value = '';
  circle.classList.add(
    'transition-all',
    'duration-300',
    cirleClasses.default,
    cirleClasses[variant],
    isInteractive ? cirleClasses.interactive : null,
  );

  text.classList.value = '';
  text.classList.add(
    'transition-all',
    'duration-300',
    textClasses.default,
    textClasses[variant],
    isInteractive ? textClasses.interactive : null,
  );

  if (background) {
    background.classList.value = '';
    background.classList.add(
      'transition-all',
      'duration-300',
      backgroundClasses.default,
      backgroundClasses[variant],
      isInteractive ? backgroundClasses.interactive : null,
    );
  }

  if (variant === SeatVariant.OCCUPIED) {
    // Add 1 whitespace for text with 2 or more characters (primitive visual centering)
    const padStart = text.textContent?.length > 1 ? '&nbsp;' : '';
    text.innerHTML = `${padStart}X`;
  } else {
    text.innerHTML = seatIndex.toString();
  }
};

const SeatsRegex = /^s(\d+)$/;

/**
 * Obarví:
 * 1. Všechna obsazená (neaktivní) sedadla
 * 2. Všechna dostupná (lze vybrat) sedadla
 * 3. Všechna uživatelem vybraná sedadla
 */
export const fillSeats = (
  coachRef: SVGElement,
  vehicleNumber: number,
  deckNumber: number,
  isPartialStorno: boolean,
  userSelectedStornoSeats: SelectedSeat[],
  occupiedSeats: Seat[],
  freeSeats: Seat[],
  seatsToFill: SeatPosition[],
  isOtherClassSeats: boolean,
): void => {
  occupiedSeats.forEach(({ index }) => fillSingleSeat(index, coachRef, SeatVariant.OCCUPIED));
  /**
   * Fix pro nové SVG - je potřeba zašednout neaktivní sedadla
   * Defaultní barva sedadel je modrá
   * Seznam obsazených sedadel chodí z backendu jen z koupě jízdenky
   * Ve všech ostatních případech (storno, zakoupená jízdenka)
   * musíme projít všechny elementy sedadel a zašednout je (odpovídá defaultnímu stavu starých SVG)
   */
  if (!occupiedSeats.length) {
    const seatElements = coachRef.querySelectorAll(`g[id^='s']`);

    seatElements.forEach((el) => {
      const seatIndex = +SeatsRegex.exec(el.id)?.[1];
      // U některých vagónů se vyberou i elementy, které nejsou sedadla a je vygenerován index 0, proto je vyhodíme.
      if (seatIndex) fillSingleSeat(seatIndex, coachRef, SeatVariant.NONSELECTABLE);
    });
  }
  freeSeats.forEach(({ index }) =>
    fillSingleSeat(index, coachRef, isOtherClassSeats ? SeatVariant.OTHER_CLASS : SeatVariant.FREE),
  );

  seatsToFill
    .filter((s) => s.vehicleNumber === vehicleNumber)
    .forEach((s) => {
      // selected seat does not exist in current deck
      if (!coachRef.querySelector(`g[id='s${s.seatIndex}']`)) return;
      const variant = getSelectedSeatVariant(
        s.seatIndex,
        isPartialStorno,
        vehicleNumber,
        deckNumber,
        userSelectedStornoSeats,
      );
      fillSingleSeat(s.seatIndex, coachRef, variant);
    });
};
