import { createContext } from 'use-context-selector';
import React, { useState } from 'react';
import { PreselectedSeat } from '@web/shop-logic/dist/hooks/useSeats';
import { PassengersToStorno } from '@web/shop-logic/dist/hooks/useTicketActions';
import { TicketSection } from '@web/shop-logic/dist/hooks/useUserTickets';
import StornoSeatingCard from '@/components/seating/StornoSeatingCard';

interface PartialStornoSeatingCardsProps {
  openStornoModal: (
    seats: PreselectedSeat[][],
    passengersIds: PassengersToStorno[],
    totalStornoPrice: number,
  ) => void;
  passengersIds: PassengersToStorno[];
  routeSections: TicketSection[];
  totalStornoPrice: number;
}

export const StornoContext = createContext<{
  index: number;
  isPartialStorno: boolean;
  seatsForStorno: PreselectedSeat[][];
  setSeatsForStorno: (seats: PreselectedSeat[][]) => void;
}>({
  index: null,
  isPartialStorno: null,
  seatsForStorno: null,
  setSeatsForStorno: null,
});

const PartialStornoSeatingCards: React.FC<PartialStornoSeatingCardsProps> = ({
  openStornoModal,
  passengersIds,
  routeSections,
  totalStornoPrice,
}) => {
  const [currentPage, setCurrentPage] = useState(0);

  const sectionsWithSeats = routeSections.filter((s) => s.selectedSeats.length);

  /**
   * seatsForStorno
   * Všechna místa ve všech spojích ve všech vagonech, které si uživatel vybral ke stornu ze `selectedSeats`
   */
  const [seatsForStorno, setSeatsForStorno] = useState<PreselectedSeat[][]>(
    sectionsWithSeats.map((routeSection) => {
      const preSelectedSeats = routeSection.selectedSeats as PreselectedSeat[];
      return preSelectedSeats.slice(0, passengersIds.length);
    }),
  );

  const handleNext = (index: number) =>
    sectionsWithSeats.length - 1 === index
      ? openStornoModal(seatsForStorno, passengersIds, totalStornoPrice)
      : setCurrentPage((prev) => prev + 1);

  return (
    <>
      {sectionsWithSeats.map(({ section, selectedSeats, vehicles }, index) => (
        <div key={section.id}>
          {currentPage === index && (
            <StornoContext.Provider
              value={{ seatsForStorno, setSeatsForStorno, index, isPartialStorno: true }}
            >
              <StornoSeatingCard
                routeSection={{
                  sectionId: section.id,
                  selectedSeats: selectedSeats as PreselectedSeat[],
                  fixedSeatReservation: true,
                  vehicles,
                }}
                onSeatSelect={() => undefined}
                handleNext={() => handleNext(index)}
              />
            </StornoContext.Provider>
          )}
        </div>
      ))}
    </>
  );
};

export default PartialStornoSeatingCards;
