import { Trans } from 'next-i18next';
import React from 'react';
import { Button, ButtonProps } from '@/components/atoms/Button';
import Price from '@/components/atoms/Price';

interface PriceButtonProps extends Exclude<ButtonProps, 'children'> {
  price: number;
  hasFromPrefix?: boolean;
  ariaLabel?: string;
}

const PriceButton: React.FC<PriceButtonProps> = ({
  hasFromPrefix,
  price,
  ariaLabel,
  ...buttonProps
}) => (
  <Button {...buttonProps}>
    {ariaLabel && <span className="sr-only">{ariaLabel}</span>}
    {hasFromPrefix && <Trans i18nKey="connections.priceFrom" />} <Price price={price} />
  </Button>
);

export default PriceButton;
