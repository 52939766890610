import WagonWheel from './WagonWheel';
import { useTranslation } from 'next-i18next';
import React from 'react';
import classNames from 'classnames';
import { Service } from '@web/shop-logic/dist/hooks/useConnectionRoute';
import ServiceIcon from '@/components/fare/ServiceIcon';

const allowedServices: Service[] = [
  'typy_vozidel_detske_kupe',
  'typy_vozidel_imobilni_rampa',
  'typy_vozidel_preprava_kola',
  'typy_vozidel_tiche_kupe',
];

interface TrainWagonProps {
  freeSeats: number;
  selected: boolean;
  services: Service[];
  vehicleNumber: number;
}

const TrainWagon: React.FC<TrainWagonProps> = ({
  freeSeats,
  selected,
  services,
  vehicleNumber,
}) => {
  const { t } = useTranslation();

  const iconClassNames = classNames(
    'w-3 h-3',
    selected ? 'fill-neutral-white' : 'fill-neutral-gray2',
  );
  const wagonServices: Service[] = services.filter((key) => allowedServices.includes(key));

  return (
    <div className="pr-0.75 border-r border-neutral-gray2 group">
      <div
        className={classNames(
          'relative w-11 h-28 flex flex-col justify-center items-center flex-shrink-0 mt-0.5 rounded-sm border border-neutral-gray2 transition-all ',
          selected && 'bg-primary-blue',
          freeSeats &&
            !selected &&
            'bg-primary-bluelight group-hover:border-secondary-bluedark group-hover:border-1',
        )}
      >
        <WagonWheel customStyle="top-1" />
        <WagonWheel customStyle="bottom-1" />

        <h3
          className={classNames('h3 ', selected ? 'text-neutral-white' : 'text-primary-blue')}
          aria-label={t('accessibility.icon.WagonLayoutIcon', {
            number: vehicleNumber,
            count: freeSeats,
          })}
        >
          {vehicleNumber}
        </h3>

        {/**
         * Ikony služeb ve voze
         */}
        {wagonServices.map((key) => (
          <ServiceIcon key={key} service={key} hasAriaLabel className={iconClassNames} />
        ))}
      </div>
    </div>
  );
};

export default TrainWagon;
