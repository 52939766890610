import { Trans, useTranslation } from 'next-i18next';
import React from 'react';
import { Service } from '@web/shop-logic/dist/hooks/useConnectionRoute';
import ServiceIcon from '@/components/fare/ServiceIcon';
import BoxLoader from '@/components/loader/BoxLoader';

interface MoreInfoContentProps {
  className?: string;
  servicesClassName?: string;
  services?: Service[];
}

const SeatClassMoreInfo: React.FC<MoreInfoContentProps> = ({
  className,
  servicesClassName,
  services = [],
}) => {
  const { t } = useTranslation();


  return services ? (
    <div className={className}>
      <ul className={servicesClassName} aria-label={t('coach.facilities')}>
        {services.map((service) => (
          <li key={service} className="mb-1.5 lg:mb-2 flex sm:text-14">
            <ServiceIcon service={service} className="w-3 h-3 mr-2" />
            <Trans i18nKey={`icons.popover.${service}`} />
          </li>
        ))}
      </ul>
    </div>
  ) : (
    <BoxLoader />
  );
};

export default SeatClassMoreInfo;
