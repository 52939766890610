import WagonWheel from './WagonWheel';
import { Trans } from 'next-i18next';
import { useRouter } from 'next/router';
import React from 'react';
import classNames from 'classnames';
import { SeatPosition } from '@web/shop-logic/dist/hooks/types';
import { SeatSection } from '@web/shop-logic/dist/hooks/useSeats';
import ClickableDiv from '@/components/atoms/ClickableDiv';
import TrainWagon from '@/components/seating/TrainWagon';

interface WagonOverviewProps {
  seatSection: SeatSection;
  selectedSeats: SeatPosition[];
  setVehicleNr: React.Dispatch<React.SetStateAction<number>>;
  vehicleNr: number;
  standardTransKey: string;
}

const WagonOverview: React.FC<WagonOverviewProps> = ({
  seatSection,
  selectedSeats,
  setVehicleNr,
  vehicleNr,
  standardTransKey,
}) => {
  const { query } = useRouter();

  return (
    <div className="flex-1">
      {seatSection.vehicles.length > 1 && (
        <div className="relative border w-11 h-20 border-neutral-gray2 rounded-sm rounded-tl-[35px]">
          <div className="absolute top-0 -right-[8px] w-px h-20 bg-neutral-gray2" />
          <WagonWheel customStyle="top-1" />
          <WagonWheel customStyle="top-2.5" />
          <WagonWheel customStyle="bottom-1" />
          <div className="relative w-full h-full rounded-sm rounded-tl-[35px] overflow-hidden">
            <div className="absolute w-5 h-20 -top-5 -left-2 border rounded-b-full border-neutral-gray2" />
            <div className="absolute w-4 h-10 -bottom-12 -right-2.5 border rounded-t-tiny border-neutral-gray2" />
          </div>
        </div>
      )}
      {seatSection.vehicles.map(({ number, decks, seatClasses = [] }) => {
        const freeSeats = decks.flatMap(d => d.freeSeats);
        const allFreeSeats = [
          ...freeSeats,
          ...(selectedSeats || []).filter((ss) => ss.vehicleNumber === number),
        ];

        const selected = number === vehicleNr;

        return (
          <ClickableDiv
            key={number}
            className="flex group"
            isClickable={!!allFreeSeats.length}
            onClick={() => setVehicleNr(number)}
            data-id={`vehicle-${number}`}
            gtmPlace="ClickableTrainWagon"
            gtmName={`Change wagon (${number === vehicleNr ? 'selected' : number})`}
          >
            {/**
             * Tlačítko pro přepnutí vagonu
             */}
            <TrainWagon
              vehicleNumber={number}
              services={seatClasses.flatMap(({ services }) =>
                services.map((s) => s.imageCode),
              )}
              selected={number === vehicleNr}
              freeSeats={allFreeSeats.length}
            />
            <div className="flex flex-col justify-center mx-1.5 lg:mr-8">
              {seatClasses.map(({ name }) => {
                const classFreeSeats = freeSeats.filter((fs) => fs.seatClass === name).length;
                const useTransKey = name === 'NO';

                return (
                  /* Název třídy, volná místa */
                  <div
                    key={name}
                    className={classNames(
                      classFreeSeats ? 'text-primary-blue' : 'text-neutral-gray',
                      'text-14',
                      classFreeSeats && !selected && 'group-hover:text-secondary-bluedark',
                    )}
                  >
                    <div className="font-bold">
                      <Trans
                        i18nKey={!!useTransKey ? standardTransKey : `PricedSeatClass.${name}`}
                      />
                    </div>
                    {!!classFreeSeats ? (
                      <div aria-hidden>
                        <Trans i18nKey="seats.count" values={{ count: classFreeSeats }} />
                      </div>
                    ) : (
                      <div
                        className={classNames(
                          name === query.seatClassKey ? 'text-14 text-secondary-redwarn' : 'hidden',
                        )}
                      >
                        <Trans i18nKey="connections.soldOut" />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </ClickableDiv>
        );
      })}
    </div>
  );
};

export default WagonOverview;
