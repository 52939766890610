import DeckerSwitcher from './DeckerSwitcher';
import { TSelectedDeck } from './SeatingCard';
import { Trans } from 'next-i18next';
import React, { Dispatch, FC, SetStateAction } from 'react';
import classNames from 'classnames';
import { SeatClass, SeatPosition } from '@web/shop-logic/dist/hooks/types';
import { PriceClass } from '@web/shop-logic/dist/hooks/useConnectionRoute';
import { Deck, SeatSection } from '@web/shop-logic/dist/hooks/useSeats';
import { Button } from '@/components/atoms/Button';
import ClickableDiv from '@/components/atoms/ClickableDiv';
import CoachSvg from '@/components/seating/CoachSvg';
import SeatConstraints from '@/components/seating/SeatConstraints';
import SeatFacilities from '@/components/seating/SeatFacilities';
import SeatWagonBadges from '@/components/seating/SeatWagonBadges';
import SeatingCardHeader from '@/components/seating/SeatingCardHeader';
import SeatingLegend from '@/components/seating/SeatingLegend';
import WagonOverview from '@/components/seating/WagonOverview';
import useMobileWidth from '@/hooks/useMobileWidth';
import { ArrowSouth, ChevronDown } from '@/icons';
import { groupBy } from '@/utils/arrayUtils';

interface SeatingCardInnerProps {
  classInfo?: Partial<Pick<PriceClass, 'services' | 'seatClassKey'>>;
  classPrices?: { priceClass: SeatClass; price: number }[];
  conditionsInfo?: Partial<Pick<PriceClass, 'conditions'>>;
  onContinue?: () => void;
  onSeatSelect: (newSeats: SeatPosition[]) => void;
  openedFromTicket?: boolean;
  openModal?: () => void;
  seatClassPhotoUrl?: string;
  seatSection: SeatSection;
  selectedSeats: SeatPosition[];
  setVehicleNr: Dispatch<SetStateAction<number>>;
  standardTransKey?: string;
  vehicleNr: number;
  selectedDeckNr: TSelectedDeck;
  setSelectedDeckNr: Dispatch<SetStateAction<TSelectedDeck>>;
  isStorno?: boolean;
  otherSeatClassDeck?: Deck;
}

const SeatingCardInner: FC<SeatingCardInnerProps> = ({
  classInfo,
  classPrices,
  conditionsInfo,
  onContinue,
  onSeatSelect,
  openedFromTicket,
  openModal,
  seatClassPhotoUrl,
  seatSection,
  selectedSeats,
  setVehicleNr,
  standardTransKey,
  vehicleNr,
  selectedDeckNr,
  setSelectedDeckNr,
  isStorno,
  otherSeatClassDeck,
}) => {
  const { isMobile } = useMobileWidth();

  const seatConstraint = selectedSeats
    ?.map(({ seatIndex, vehicleNumber }) =>
      seatSection.vehicles
        .find((v) => v.number === vehicleNumber)
        .decks.find((d) => d.number === selectedDeckNr)
        .freeSeats.find(({ index }) => index === seatIndex),
    )
    ?.filter((s) => s?.seatConstraint);

  const groupedConstraints = Array.from(
    groupBy(seatConstraint, (s) => s?.seatConstraint).entries(),
  );

  const isDoubleDecker =
    seatSection.vehicles[0]?.type === 'BUS' && seatSection.vehicles[0].decks.length === 2;
  const currentVehicle = seatSection.vehicles.find((v) => v.number === vehicleNr);
  const currentDeck = currentVehicle?.decks.find((d) => d.number === selectedDeckNr);
  const otherDeck = currentVehicle?.decks.find((d) => d.number !== selectedDeckNr);
  const firstSelectedDeck = seatSection?.selectedSeats[0]?.vehicleDeckNumber;

  const switchDecks = (): void =>
    setSelectedDeckNr((prevDeck) =>
      prevDeck === selectedDeckNr && currentVehicle?.decks.length === 2
        ? otherDeck.number
        : selectedDeckNr,
    );

  const getCorrectDeck = (deck: Deck) => {
    if (!isDoubleDecker || !otherSeatClassDeck) return deck;
    if (deck.number === firstSelectedDeck) return deck;
    return otherSeatClassDeck;
  };

  return (
    <div className="w-full lg:mb-3 flex-col rounded-sm lg:border lg:border-neutral-gray3">
      {isMobile && standardTransKey && (
        <div className="flex items-center justify-between border-t border-neutral-gray3">
          <h4 className="h4 py-1">
            <Trans i18nKey={standardTransKey} />
          </h4>
          {openModal && (
            <ClickableDiv className="flex items-center text-primary-blue" onClick={openModal}>
              <Trans i18nKey="coach.facilities" />
              <ChevronDown className="w-2 h-2 fill-current" />
            </ClickableDiv>
          )}
        </div>
      )}
      {/* Header: Vybraná sedadla */}
      <SeatingCardHeader
        removeWagons={seatSection.vehicles.length === 1}
        classPrices={classPrices}
        onContinue={onContinue}
        seatClass={classInfo?.seatClassKey}
        vehicleKey={currentVehicle?.type === 'BUS' && currentVehicle.standard}
        openedFromTicket={openedFromTicket}
        seatConstraint={groupedConstraints}
        selectedSeats={selectedSeats}
        isStorno={isStorno}
      />
      <hr className="border-neutral-gray3" />
      <div
        className={classNames(
          'pt-3 lg:p-3 flex sm:justify-between',
          !openedFromTicket && 'justify-end',
        )}
      >
        <div className={classNames('flex flex-col', !openedFromTicket && 'lg:w-full')}>
          {/* Double deck switcher */}
          {!!isDoubleDecker && (
            <DeckerSwitcher
              otherSeatClassDeck={otherSeatClassDeck}
              seatSection={seatSection}
              selectedDeckNr={selectedDeckNr}
              switchDecks={switchDecks}
            />
          )}
          {/* Seating legend map */}
          <SeatingLegend isDoubleDecker={isDoubleDecker} />

          {isDoubleDecker ? (
            <div className="flex justify-center">
              {currentVehicle && (
                <div className="flex gap-4 sm:gap-0">
                  {currentVehicle.decks.map((deck) => (
                    <div
                      className={selectedDeckNr !== deck.number ? 'cursor-pointer' : undefined}
                      key={`${deck.name}-${deck.number}`}
                    >
                      <CoachSvg
                        vehicleNumber={currentVehicle.number}
                        switchDecks={switchDecks}
                        otherDeck={otherDeck}
                        deck={getCorrectDeck(deck)}
                        selectedSeats={selectedSeats}
                        onSeatSelect={onSeatSelect}
                        seatSectionId={seatSection.sectionId}
                        selectedDeckNr={selectedDeckNr}
                        doubleDecker
                        firstSelectedDeck={firstSelectedDeck}
                        key={`${deck.name}-${deck.number}`}
                        openedFromTicket={openedFromTicket}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          ) : (
            <div className="flex">
              {seatSection.vehicles.length > 1 && (
                /* Směr jízdy */
                <div className="lg:w-12 relative" aria-hidden>
                  <div
                    style={{ writingMode: 'vertical-rl' }}
                    className="absolute flex items-center transform rotate-180 pointer-events-none -top-2 lg:top-0 left-14 lg:left-0 sm:text-12 sm:max-h-[120px] sm:text-right lg:font-bold"
                  >
                    <Trans i18nKey="reservation.direction" />
                    <ArrowSouth className="w-3 mt-1 fill-current" />
                  </div>
                </div>
              )}
              {/* SLOUPEC: Přehled všech vagonů */}

              <div className={classNames(openedFromTicket && 'mr-5')}>
                <WagonOverview
                  standardTransKey={standardTransKey}
                  seatSection={seatSection}
                  selectedSeats={selectedSeats}
                  setVehicleNr={setVehicleNr}
                  vehicleNr={vehicleNr}
                />
              </div>

              {/**
               * SLOUPEC: Obrázek vagonu
               */}

              {currentVehicle && (
                <CoachSvg
                  vehicleNumber={currentVehicle.number}
                  deck={currentDeck}
                  selectedSeats={selectedSeats}
                  onSeatSelect={onSeatSelect}
                  seatSectionId={seatSection.sectionId}
                />
              )}
            </div>
          )}
        </div>

        {classInfo && (
          <>
            {/**
             * SLOUPEC: Vertikální čára
             */}
            <div className="sm:hidden mx-2 border-l border-neutral-gray3" />

            {/**
             * SLOUPEC: Vybavení vozu
             */}
            {!isMobile && (
              // Pokud máme autobus+vlak je rozhozený layout, min-w zajišťuje nutnou minimální šířku
              <div className="w-1/3 min-w-[33.3333%]">
                <SeatFacilities
                  classInfo={classInfo}
                  conditionsInfo={conditionsInfo}
                  openedFromTicket={openedFromTicket}
                  seatClassPhotoUrl={seatClassPhotoUrl}
                  standardTransKey={standardTransKey}
                />
                {!openedFromTicket && (
                  <>
                    <div className="mt-4">
                      <h4 className="h4">
                        <Trans i18nKey="reservation.selectedSeats" />
                      </h4>
                      <SeatWagonBadges
                        removeWagon={
                          classPrices?.some((c) => c.priceClass === 'NO') ||
                          seatSection.vehicles.length === 1
                        }
                        className="my-2 flex flex-col gap-1 items-start"
                        selectedSeats={selectedSeats}
                      />
                    </div>
                    <SeatConstraints seatConstraint={groupedConstraints} />
                  </>
                )}
              </div>
            )}
          </>
        )}
      </div>
      {isMobile && onContinue && (
        <div className="py-1 bg-neutral-white absolute bottom-0 sticky">
          <Button className="w-full" size="big" onClick={onContinue}>
            <Trans i18nKey="button.next" />
          </Button>
        </div>
      )}
    </div>
  );
};

export default SeatingCardInner;
