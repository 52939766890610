import ClickableDiv from '../atoms/ClickableDiv';
import { TSelectedDeck } from './SeatingCard';
import { Trans } from 'next-i18next';
import React, {FC, Fragment} from 'react';
import classNames from 'classnames';
import { Deck, SeatSection } from '@web/shop-logic/dist/hooks/useSeats';
import { ArrowSyncAlt } from '@/icons';

//
// TODO
// Hide the count only from ticket seat view and show 0 with according color when booking it
// Add this feature when we fetch data for other class as well
//

interface DeckerSwitcherProps {
  seatSection: SeatSection;
  selectedDeckNr: TSelectedDeck;
  switchDecks: () => void;
  otherSeatClassDeck?: Deck;
}

const DeckerSwitcher: FC<DeckerSwitcherProps> = ({
  seatSection,
  selectedDeckNr,
  switchDecks,
  otherSeatClassDeck,
}) => {
  const otherClassFreeSeats = otherSeatClassDeck?.freeSeats?.length;

  return (
    <div className="flex items-center justify-around mb-3">
      {seatSection.vehicles[0].decks.map(({ number, name, freeSeats }, index) => {
        const isSelected = selectedDeckNr === number;
        const deckTransKey = number === 1 ? 'deck.bottom' : 'deck.upper';
        const renderArrowSwitcher = index === 0;
        const freeSeatsLength = freeSeats.length;

        return (
          <Fragment key={number}>
            <ClickableDiv
              key={`${name}-${number}`}
              onClick={switchDecks}
              className={classNames(
                'flex flex-col items-center sm:pb-1 px-2 sm:px-1',
                isSelected && 'border-b-2 border-primary-blue',
              )}
            >
              <h4 className="text-h4 sm:text-16 text-primary-blue">
                <Trans i18nKey={deckTransKey} />
              </h4>
              <div className="flex gap-2 text-14 pb-1 sm:hidden">
                <p className="lowercase first-letter:uppercase">{name}</p>
                {!!freeSeatsLength && (
                  <p className={classNames(isSelected && 'text-secondary-green')}>
                    <Trans i18nKey="seats.count" count={freeSeatsLength} />
                  </p>
                )}
                {!!otherClassFreeSeats && number === otherSeatClassDeck.number && (
                  <p className={classNames(isSelected && 'text-secondary-green')}>
                    <Trans i18nKey="seats.count" count={otherClassFreeSeats} />
                  </p>
                )}
              </div>
            </ClickableDiv>
            {!!renderArrowSwitcher && (
              <ClickableDiv onClick={switchDecks} className="mx-2">
                <ArrowSyncAlt className="w-4 h-4 fill-primary-blue" />
              </ClickableDiv>
            )}
          </Fragment>
        );
      })}
    </div>
  );
};

export default DeckerSwitcher;
