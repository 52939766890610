import { Trans, useTranslation } from 'next-i18next';
import React from 'react';
import { SeatClass, SeatPosition } from '@web/shop-logic/dist/hooks/types';
import { VehicleKey } from '@web/shop-logic/dist/hooks/types';
import { Seat } from '@web/shop-logic/dist/hooks/useSeats';
import { Button } from '@/components/atoms/Button';
import ClickableDiv from '@/components/atoms/ClickableDiv';
import SeatClassName from '@/components/fare/SeatClassName';
import ModalContext from '@/components/modal/ModalContext';
import useModal from '@/components/modal/useModal';
import SeatConstraints from '@/components/seating/SeatConstraints';
import SeatWagonBadges from '@/components/seating/SeatWagonBadges';
import useContextSelector from '@/hooks/useContextSelector';
import useMobileWidth from '@/hooks/useMobileWidth';
import { ChevronRight, Error } from '@/icons';

interface SeatingCardHeaderProps {
  classPrices?: { priceClass: SeatClass; price: number }[];
  onContinue: () => void;
  openedFromTicket?: boolean;
  seatClass?: SeatClass;
  vehicleKey?: VehicleKey;
  seatConstraint?: [string, Seat[]][];
  selectedSeats: SeatPosition[];
  isStorno?: boolean;
  removeWagons?: boolean;
}
const SeatingCardHeader: React.FC<SeatingCardHeaderProps> = ({
  classPrices,
  onContinue,
  openedFromTicket,
  seatClass,
  seatConstraint,
  selectedSeats,
  vehicleKey,
  isStorno,
  removeWagons,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useMobileWidth();
  const { showModal } = useModal();
  const hideAllModals = useContextSelector(ModalContext, (c) => c.hideAllModals);

  return (
    <div className="lg:px-3 py-0.5 flex items-center justify-between">
      <div className="sm:w-full flex sm:flex-col sm:justify-between lg:items-center gap-1 lg:gap-2 sm:-mt-1">
        <h3 className="h4 sm:hidden">
          <Trans i18nKey="reservation.selectedSeats" />
        </h3>
        <div className="flex flex-wrap gap-1 items-center my-1">
          <SeatWagonBadges
            removeWagon={classPrices?.some((c) => c.priceClass === 'NO') || removeWagons}
            className="flex flex-wrap gap-1 w-max"
            selectedSeats={selectedSeats}
          />
          {!openedFromTicket && isMobile && !!seatConstraint.length && (
            <>
              <Error className="w-3 h-3 fill-secondary-red" />
              <ClickableDiv
                className="flex items-center text-14 text-primary-blue"
                onClick={() =>
                  showModal(
                    <div className="flex flex-col gap-2">
                      <SeatWagonBadges
                        removeWagon={
                          classPrices?.some((c) => c.priceClass === 'NO') || removeWagons
                        }
                        className="flex flex-wrap gap-1 w-max"
                        selectedSeats={selectedSeats}
                      />
                      <SeatConstraints seatConstraint={seatConstraint} />
                      <Button onClick={hideAllModals}>
                        <Trans i18nKey="understand" />{' '}
                      </Button>
                    </div>,
                    { title: t('seat.constraint.info') },
                  )
                }
              >
                <Trans i18nKey="seat.constraint.info" />{' '}
                <ChevronRight className="w-3 h-3 fill-current" />
              </ClickableDiv>
            </>
          )}
        </div>

        {seatClass && (
          <div className="text-14 text-neutral-gray">
            <Trans i18nKey="passenger.tariff" />:{' '}
            <SeatClassName seatClass={seatClass} type="RJ_SEAT" standard={vehicleKey} />
          </div>
        )}
      </div>
      {(!openedFromTicket || isStorno) && (
        <Button className="sm:hidden" size="big" onClick={onContinue}>
          <Trans i18nKey="button.next" />
        </Button>
      )}
    </div>
  );
};

export default SeatingCardHeader;
