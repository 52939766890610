import { Trans } from 'next-i18next';
import React from 'react';
import classNames from 'classnames';
import { StornoContext } from '@/components/seating/PartialStornoSeatingCards';
import useContextSelector from '@/hooks/useContextSelector';
import { SeatVariant, seatVariantColors } from '@/utils/seatingFillUtils';

const circleClasses = 'sm:w-2.5 w-3 sm:h-2.5 h-3 rounded-full mr-1 sm:mr-0.5';

interface SeatingLegendProps {
  isDoubleDecker?: boolean;
}

const SeatingLegend: React.FC<SeatingLegendProps> = ({ isDoubleDecker }) => {
  const isPartialStorno = useContextSelector(StornoContext, (c) => c.isPartialStorno);

  return (
    <div
      className={classNames(
        'flex flex-wrap mb-4 gap-x-2 lg:gap-x-3 gap-y-1 sm:text-12',
        isDoubleDecker && 'text-14',
      )}
      aria-hidden
    >
      {/* Vybrané místo */}
      <div className="flex items-center">
        <div
          className={classNames(circleClasses, `bg-${seatVariantColors[SeatVariant.SELECTED]}`)}
        />
        <Trans
          i18nKey={
            isPartialStorno
              ? 'ticket.partial.storno.seating.map.bought.sign'
              : 'reservation.seating.legend.selected.seat'
          }
        />
      </div>

      {/* Volné místo */}
      <div className="flex items-center">
        <div
          className={classNames(
            circleClasses,
            isPartialStorno
              ? `bg-${seatVariantColors[SeatVariant.STORNO_SELECTED]}`
              : `bg-${seatVariantColors[SeatVariant.FREE]}`,
          )}
        />
        <Trans
          i18nKey={
            isPartialStorno
              ? 'ticket.partial.storno.seating.map.storno.sign'
              : 'reservation.seating.legend.free.seat'
          }
        />
      </div>

      {/* Obsazené místo */}
      <div className="flex items-center">
        <div
          className={classNames(
            circleClasses,
            `bg-${seatVariantColors[SeatVariant.OCCUPIED]}`,
            'flex justify-center items-center text-white text-14 leading-none',
          )}
        >
          X
        </div>
        <Trans i18nKey="ticket.partial.storno.seating.map.occupied.sign" />
      </div>

      {/* Volné místo v jiné třídě */}
      {isDoubleDecker && (
        <div className="flex items-center">
          <div
            className={classNames(
              circleClasses,
              `bg-${seatVariantColors[SeatVariant.OTHER_CLASS]}`,
              'border border-neutral-gray3',
            )}
          />
          <Trans i18nKey="deck.otherClass.freeSeats" />
        </div>
      )}
    </div>
  );
};

export default SeatingLegend;
